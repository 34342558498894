import styled from "@emotion/styled";
import { ThemeProvider } from "@mui/material";
import { SnackbarProvider } from "notistack";
import React from "react";
import Drawer from "./components/Drawer";
import ErrorBoundary from "./components/ErrorBoundary";
import HistoryVersionSelector from "./components/HistoryVersionSelector";
import OpenAITable from "./components/OpenAITable";
import Prompt from "./components/Prompt";
import { GlobalStateContextProvider } from "./GlobalStateContext";
import { HistoryContextProvider } from "./HistoryContext";
import { theme } from "./theme";

const Brand = styled.div(({ theme }) => ({
  display: "flex",
  alignItems: "flex-end",
  color: "#fff",
}));

const Heading = styled.h1(({ theme }) => ({
  color: "#EEE",
  textAlign: "center",
  marginBottom: 0,
  marginRight: 5,
}));

const Container = styled.div((props) => ({
  display: "flex",
  flexDirection: "column",
  margin: "1vw 5vw 150px 5vw",
}));

const Header = styled.div((props) => ({
  display: "flex",
  flexDirection: "row",
  justifyContent: "space-between",
  alignItems: "center",
  marginBottom: 20,
}));

function App() {
  return (
    <SnackbarProvider>
      <ThemeProvider theme={theme}>
        <GlobalStateContextProvider>
          <HistoryContextProvider>
            <div className="App">
              <Container>
                <Header>
                  <Brand>
                    <Heading>🤖 AI-table</Heading>
                    <span>
                      by{" "}
                      <a
                        href="https://www.linkedin.com/in/asas-innab/"
                        target="_blank"
                        rel="noreferrer"
                        style={{
                          lineHeight: 1.8,
                        }}
                      >
                        Asas Innab
                      </a>
                    </span>
                  </Brand>
                  <Drawer />
                </Header>
                <HistoryVersionSelector />
                <ErrorBoundary>
                  <OpenAITable />
                </ErrorBoundary>
                <Prompt />
              </Container>
            </div>
          </HistoryContextProvider>
        </GlobalStateContextProvider>
      </ThemeProvider>
    </SnackbarProvider>
  );
}

export default App;
