import React, { useState } from "react";
import MuiDrawer from "@mui/material/Drawer";
import IconButton from "@mui/material/IconButton";
import MenuIcon from "@mui/icons-material/Menu";
import CloseIcon from "@mui/icons-material/Close";
import {
  Avatar,
  Box,
  List,
  ListItem,
  ListItemAvatar,
  ListItemButton,
  ListItemText,
  ListSubheader,
  Slider,
  Typography,
} from "@mui/material";
import OpenInNewIcon from "@mui/icons-material/OpenInNew";
import { useGlobalState } from "../GlobalStateContext";
import styled from "@emotion/styled";

const CloseButtonContainer = styled.div((props) => ({
  display: "flex",
  margin: "5px 5px 15px 0",
}));

const Drawer = () => {
  const [globalState, setGlobalState] = useGlobalState();
  const [isOpen, setIsOpen] = useState(false);

  const toggleDrawer = () => {
    setIsOpen((prev) => !prev);
  };

  const handleTemperatureChange = (event: Event, value: number | number[]) => {
    setGlobalState((prev) => ({ ...prev, temperature: value as number }));
  };

  const handleAmountCompletionsChange = (
    event: Event,
    value: number | number[]
  ) => {
    setGlobalState((prev) => ({ ...prev, amountCompletions: value as number }));
  };

  return (
    <div>
      <IconButton
        onClick={toggleDrawer}
        aria-label="toggle settings"
        component="label"
      >
        <MenuIcon />
      </IconButton>
      <MuiDrawer
        PaperProps={{
          sx: { width: "300px", zIndex: 1300, overflow: "hidden" },
        }}
        sx={{ zIndex: 1500 }}
        anchor="right"
        open={isOpen}
        onClose={toggleDrawer}
      >
        <Box role="presentation">
          <CloseButtonContainer>
            <IconButton
              sx={{ marginLeft: "auto" }}
              onClick={toggleDrawer}
              aria-label="close settings"
              size="small"
            >
              <CloseIcon fontSize="small" />
            </IconButton>
          </CloseButtonContainer>
          <List disablePadding>
            <ListItem
              disablePadding
              secondaryAction={
                <IconButton
                  edge="end"
                  aria-label="open linkedin profile asas innab"
                  size="small"
                  href="https://www.linkedin.com/in/asas-innab/"
                  target="_blank"
                >
                  <OpenInNewIcon fontSize="small" />
                </IconButton>
              }
            >
              <ListItemButton
                href="https://www.linkedin.com/in/asas-innab/"
                target="_blank"
              >
                <ListItemAvatar>
                  <Avatar alt="Asas Innab" src="/asas.jpg" />
                </ListItemAvatar>
                <ListItemText
                  primary={"Asas Innab"}
                  secondary="AI web engineer"
                />
              </ListItemButton>
            </ListItem>
          </List>

          <List
            subheader={<ListSubheader component="div">Settings</ListSubheader>}
          >
            <ListItem
              sx={{ flexDirection: "column", alignItems: "flex-start" }}
            >
              <Typography
                gutterBottom
                sx={{
                  fontSize: "14px",
                  color: "rgba(255,255,255,0.7)",
                }}
              >
                Temperature: <strong>{globalState.temperature}</strong>
              </Typography>
              <Slider
                aria-label="Temperature"
                value={globalState.temperature}
                valueLabelDisplay="off"
                onChange={handleTemperatureChange}
                step={0.1}
                marks
                min={0}
                max={1}
              />
              <Typography
                gutterBottom
                sx={{
                  fontSize: "12px",
                  color: "rgba(255,255,255,0.2)",
                  marginTop: "3px",
                  lineHeight: "1.2",
                }}
              >
                Controls the level of randomness in the output. Higher values
                result in more diverse output, while lower values result in more
                predictable output
              </Typography>
            </ListItem>
            <ListItem
              sx={{ flexDirection: "column", alignItems: "flex-start" }}
            >
              <Typography
                gutterBottom
                sx={{
                  fontSize: "14px",
                  color: "rgba(255,255,255,0.7)",
                }}
              >
                Amount of completions:{" "}
                <strong>{globalState.amountCompletions}</strong>
              </Typography>
              <Slider
                aria-label="Amount of completions"
                value={globalState.amountCompletions}
                onChange={handleAmountCompletionsChange}
                valueLabelDisplay="off"
                step={1}
                marks
                min={1}
                max={5}
              />
              <Typography
                gutterBottom
                sx={{
                  fontSize: "12px",
                  color: "rgba(255,255,255,0.2)",
                  marginTop: "3px",
                  lineHeight: "1.2",
                }}
              >
                Specifies the number of completions to generate for a given
                instruction
              </Typography>
            </ListItem>
          </List>
        </Box>
      </MuiDrawer>
    </div>
  );
};

export default Drawer;
