import { createTheme } from "@mui/material";

export const theme = createTheme({
  palette: {
    mode: "dark",
    primary: {
      main: "#999",
    },
    secondary: {
      main: "#f50057",
    },
    background: {
      default: "#343541",
    },
  },
  components: {
    MuiFilledInput: {
      styleOverrides: {
        root: {
          background: "#464752",
          borderRadius: "5px",
        },
      },
    },
    MuiListSubheader: {
      styleOverrides: {
        root: {
          backgroundColor: "#00000022",
        },
      },
    },
  },
});
