import axios from "axios";
import { API_URL } from "./constants";

export const editWithopenAI = async (
  input: string,
  instruction: string,
  temperature: number,
  amountCompletions: number
) => {
  const completion = await axios
    .post(`${API_URL}/generate-table`, {
      input,
      instruction,
      temperature,
      n: amountCompletions,
    })
    .then(({ data }) => data)
    .catch((error) => {
      console.error({ error });
      return [];
    });

  return completion;
};
