import styled from "@emotion/styled";
import { ToggleButton, ToggleButtonGroup, Tooltip } from "@mui/material";
import { useHistory } from "../HistoryContext";
import ArrowForwardIcon from "@mui/icons-material/ArrowForward";

export const ORIGINAL_INDEX = -1;

const HistoryContainer = styled.div((props) => ({
  display: "flex",
  alignItems: "flex-end",
  justifyContent: "space-between",
  flexWrap: "wrap",
  marginBottom: "20px",
}));

const ControlContainer = styled.div((props) => ({
  display: "flex",
  flexDirection: "column",
}));

const Label = styled.div((props) => ({
  color: "#FFFFFF22",
  marginBottom: "5px",
  fontSize: 14,
}));

const VersionSelector = styled.div(({ theme }) => ({
  display: "flex",
  alignItems: "center",
  color: "white",
}));

const HistoryVersionSelector = () => {
  const {
    history,
    selectTable,
    selectedHistoryIndex,
    setSelectedHistoryIndex,
  } = useHistory();

  const selectedHistory = history[selectedHistoryIndex];
  const tables = selectedHistory.tables;
  const selectedVersion = selectedHistory.selectedTableIndex;

  const handleSelectIteration = (
    event: React.MouseEvent<HTMLElement>,
    newValue: number | null
  ) => {
    if (newValue !== null) {
      setSelectedHistoryIndex(newValue);
    }
  };

  const handleSelectTableVersion = (
    event: React.MouseEvent<HTMLElement>,
    newValue: number | null
  ) => {
    if (newValue !== null) {
      selectTable(selectedHistory, newValue);
    }
  };

  return (
    <HistoryContainer>
      <ControlContainer>
        <Label>History</Label>
        <ToggleButtonGroup
          color="primary"
          exclusive
          value={selectedHistoryIndex}
          onChange={handleSelectIteration}
          size="small"
        >
          {history?.map((v, index) => (
            <ToggleButton value={index} key={index}>
              {index + 1}
            </ToggleButton>
          ))}
        </ToggleButtonGroup>
      </ControlContainer>
      <ControlContainer>
        <Label>{!selectedHistory.promptedTable ? "Variant" : " "}</Label>
        <VersionSelector>
          {selectedHistory.promptedTable && (
            <>
              <ToggleButtonGroup
                color="primary"
                size="small"
                exclusive
                value={selectedVersion}
                onChange={handleSelectTableVersion}
              >
                <ToggleButton value={ORIGINAL_INDEX}>PROMPTED</ToggleButton>
              </ToggleButtonGroup>
              <Tooltip
                open
                arrow
                placement="top"
                title={selectedHistory.instruction}
              >
                <ArrowForwardIcon />
              </Tooltip>
            </>
          )}
          <ToggleButtonGroup
            color="primary"
            size="small"
            exclusive
            value={selectedVersion}
            onChange={handleSelectTableVersion}
          >
            {tables?.map((v, index) => (
              <ToggleButton value={index} key={index}>
                {index + 1}
              </ToggleButton>
            ))}
          </ToggleButtonGroup>
        </VersionSelector>
      </ControlContainer>
    </HistoryContainer>
  );
};

export default HistoryVersionSelector;
