import { createContext, useContext, useState } from "react";
import { animals, programmingLanguages, movies } from "./initialData";

const initialData = [
  {
    instruction: "Initial table",
    tables: [animals, movies, programmingLanguages],
    selectedTableIndex: 0,
  },
];

export type TableDataValue = string | number | undefined | object | any[];

export type TableData = Array<{
  [key: string]: TableDataValue;
}>;

export interface HistoryData {
  instruction: string;
  tables: TableData[];
  selectedTableIndex: number;
  promptedTable?: TableData;
}
export type History = HistoryData[];

export interface HistoryContextType {
  history: History;
  selectTable: (historyData: HistoryData, tableIndex: number) => void;
  push: (historyEntry: HistoryData) => void;
  selectedHistoryIndex: number;
  setSelectedHistoryIndex: (index: number) => void;
}

export const HistoryContext = createContext<HistoryContextType>({
  history: [],
  selectTable: () => {},
  push: () => {},
  selectedHistoryIndex: 0,
  setSelectedHistoryIndex: () => {},
});

export interface HistoryContextProviderProps {
  children: React.ReactNode;
}

export const HistoryContextProvider = ({
  children,
}: HistoryContextProviderProps) => {
  const [history, setHistory] = useState<History>(initialData);
  const [selectedHistoryIndex, setSelectedHistoryIndex] = useState(0);

  const selectTable = (historyData: HistoryData, tableIndex: number) => {
    const index = history.findIndex((h) => h === historyData);
    const historyCopy = [...history];
    historyCopy[index].selectedTableIndex = tableIndex;
    setHistory(historyCopy);
  };

  const push = (historyEntry: HistoryData) => {
    setHistory([...history, historyEntry]);
  };

  return (
    <HistoryContext.Provider
      value={{
        history,
        selectTable,
        push,
        selectedHistoryIndex,
        setSelectedHistoryIndex,
      }}
    >
      {children}
    </HistoryContext.Provider>
  );
};

export const useHistory = () => useContext(HistoryContext);

HistoryContext.displayName = "HistoryContext";
