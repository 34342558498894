import * as React from "react";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import Paper from "@mui/material/Paper";
import { Stack } from "@mui/system";
import { Chip } from "@mui/material";
import { TableData, TableDataValue } from "../HistoryContext";

export interface BasicTableProps {
  data: TableData;
}

const renderValue = (value: TableDataValue) => {
  if (Array.isArray(value)) {
    return (
      <Stack direction="row" spacing={1} justifyContent="flex-end">
        {value.map((element) => (
          <Chip key={element} label={element} size="small" />
        ))}
      </Stack>
    );
  }

  if (typeof value === "object") {
    return (
      <Stack direction="row" spacing={1} justifyContent="flex-end">
        {Object.entries(value).map(([key, value]) => (
          <Chip key={key} label={`${key}: ${value}`} variant="outlined" />
        ))}
      </Stack>
    );
  }
  return value?.toString();
};

const BasicTable = ({ data }: BasicTableProps) => {
  return (
    <TableContainer
      component={Paper}
      sx={{
        background: "#2a2e32",
        boxShadow: "0 38px 95px 0 rgb(0 0 0 / 25%)",
      }}
    >
      <Table
        sx={{ minWidth: 650, borderColor: "#FFFFFF09" }}
        aria-label="simple table"
      >
        <TableHead>
          <TableRow>
            {Object.keys(data?.[0]).map((key, index) => (
              <TableCell
                key={key.toString()}
                align={index === 0 ? "left" : "right"}
                sx={{
                  borderBottomColor: "inherit",
                  color: "rgba(255,255,255,0.5)",
                }}
              >
                {key}
              </TableCell>
            ))}
          </TableRow>
        </TableHead>
        <TableBody>
          {data.map((row, index) => (
            <TableRow
              key={`${row.name}_${index}`}
              sx={{ "&:last-child td, &:last-child th": { border: 0 } }}
            >
              {Object.entries(row).map(([key, value], index) => (
                <TableCell
                  key={key.toString()}
                  component={index === 0 ? "th" : "td"}
                  scope="row"
                  align={index === 0 ? "left" : "right"}
                  sx={{ borderBottomColor: "inherit" }}
                >
                  {renderValue(value)}
                </TableCell>
              ))}
            </TableRow>
          ))}
        </TableBody>
      </Table>
    </TableContainer>
  );
};

export default React.memo(BasicTable);
