import BasicTable from "./BasicTable";
import { useHistory } from "../HistoryContext";
import { ORIGINAL_INDEX } from "./HistoryVersionSelector";

const OpenAITable = () => {
  const { history, selectedHistoryIndex } = useHistory();

  const selectedHistory = history[selectedHistoryIndex];
  const tables = selectedHistory.tables;
  const selectedVersion = selectedHistory.selectedTableIndex;
  const selectedData =
    selectedVersion === ORIGINAL_INDEX
      ? selectedHistory.promptedTable!
      : tables[selectedVersion];

  return <BasicTable data={selectedData} />;
};

export default OpenAITable;
