import styled from "@emotion/styled";
import { FilledInput, IconButton, InputAdornment } from "@mui/material";
import SendIcon from "@mui/icons-material/Send";
import { useEffect, useState } from "react";
import CircularProgress from "@mui/material/CircularProgress";
import { editWithopenAI } from "../OpenAI";
import { useHistory } from "../HistoryContext";
import { useSnackbar } from "notistack";
import {
  animalPrompts,
  animals,
  moviePrompts,
  movies,
  programmingLanguages,
  programmingLanguagesPrompts,
} from "../initialData";
import { getRandomElement } from "../helpers";
import { useGlobalState } from "../GlobalStateContext";

const Container = styled.form(({ theme }) => ({
  position: "fixed",
  display: "flex",
  bottom: 0,
  left: 0,
  right: 0,
  padding: "30px 20px",
  margin: 0,
  background: "#272b2fFa",
  justifyContent: "center",
}));

const Prompt = () => {
  const [prompt, setPrompt] = useState("Add units to all values");
  const { enqueueSnackbar, closeSnackbar } = useSnackbar();
  const {
    history,
    push: historyPush,
    selectedHistoryIndex,
    setSelectedHistoryIndex,
  } = useHistory();
  const [isLoading, setIsLoading] = useState(false);
  const [{ amountCompletions, temperature }] = useGlobalState();

  const selectedHistory = history[selectedHistoryIndex];
  const tables = selectedHistory.tables;
  const selectedVersion = selectedHistory.selectedTableIndex;
  const selectedData = tables[selectedVersion];

  // assign a random predifined prompt for predifined tables
  useEffect(() => {
    let prompt = "";
    if (selectedData === animals) {
      prompt = getRandomElement(animalPrompts);
    }
    if (selectedData === movies) {
      prompt = getRandomElement(moviePrompts);
    }
    if (selectedData === programmingLanguages) {
      prompt = getRandomElement(programmingLanguagesPrompts);
    }

    if (prompt !== "") {
      setPrompt(prompt);
    }
  }, [selectedData, selectedHistoryIndex]);

  const handleChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setPrompt(event.target.value);
  };

  const handleSubmit = async () => {
    try {
      setIsLoading(true);
      const stringData = JSON.stringify(selectedData);

      const openAIResponse = await editWithopenAI(
        stringData,
        prompt,
        temperature,
        amountCompletions
      );

      if (openAIResponse.length === 0) {
        const id = enqueueSnackbar("Something went wrong. Try again.", {
          anchorOrigin: {
            vertical: "top",
            horizontal: "right",
          },
          onClick: () => closeSnackbar(id),
        });
        return;
      }

      historyPush({
        tables: openAIResponse,
        instruction: prompt,
        selectedTableIndex: 0,
        promptedTable: selectedData,
      });

      setSelectedHistoryIndex(history.length);
    } catch (e) {
    } finally {
      setIsLoading(false);
    }
  };

  return (
    <Container onSubmit={handleSubmit}>
      <FilledInput
        id="openai-prompt"
        value={prompt}
        onChange={handleChange}
        fullWidth
        autoFocus
        disabled={isLoading}
        disableUnderline
        sx={{
          borderRadius: "5px",
          maxWidth: "900px",

          ".MuiInputBase-input": {
            padding: "12px",
          },
        }}
        endAdornment={
          <InputAdornment position="end">
            {isLoading ? (
              <CircularProgress size={25} />
            ) : (
              <IconButton
                type="submit"
                aria-label="submit prompt"
                onClick={handleSubmit}
                edge="end"
                sx={{
                  borderRadius: 1,
                  marginRight: "-6px",
                }}
              >
                <SendIcon />
              </IconButton>
            )}
          </InputAdornment>
        }
      />
    </Container>
  );
};

export default Prompt;
