export const animals = [
  {
    name: "Tiger",
    size: "Large",
    weight: 150,
    diet: "Carnivorous",
    habitat: "Jungle",
  },
  {
    name: "Penguin",
    size: "Medium",
    weight: 20,
    diet: "Carnivorous",
    habitat: "Arctic",
  },
  {
    name: "Gorilla",
    size: "Large",
    weight: 150,
    diet: "Herbivorous",
    habitat: "Jungle",
  },
  {
    name: "Goldfish",
    size: "Small",
    weight: 0.5,
    diet: "Omnivorous",
    habitat: "Freshwater",
  },
  {
    name: "Honeybee",
    size: "Tiny",
    weight: 0.1,
    diet: "Omnivorous",
    habitat: "Beehive",
  },
];

export const animalPrompts = [
  "Add a new animal to the table with the name 'Zebra' and its corresponding physical characteristics",
  "Edit the weight of the Gorilla in the table to be 200 pounds",
  "Filter the animals table to only show animals that live in the jungle",
  "Add a new column to the table for average lifespan and populate the cells with the appropriate data",
  "Edit the diet of the Goldfish in the table to be 'Carnivorous'",
  "Sort the animals table by weight in ascending order",
  "Add a new animal to the table with the name 'Puma' and its corresponding physical characteristics",
  "Edit the habitat of the Honeybee in the table to be 'Flowers and Beehive'",
  "Filter the animals table to only show animals that weigh less than 50 pounds",
  "Add a new column to the table for average speed and populate the cells with the appropriate data",
  "Edit the size of the Tiger in the table to be 'Medium'",
  "Sort the animals table by diet in alphabetical order",
  "Add a new animal to the table with the name 'Otter' and its corresponding physical characteristics",
  "Edit the habitat of the Gorilla in the table to be 'Rainforest'",
  "Filter the animals table to only show animals that are carnivorous or omnivorous",
  "Add a new column to the table for conservation status and populate the cells with the appropriate data",
  "Edit the weight of the Penguin in the table to be 25 pounds",
  "Sort the animals table by habitat in alphabetical order",
  "Add a new animal to the table with the name 'Panda' and its corresponding physical characteristics",
  "Edit the size of the Goldfish in the table to be 'Tiny'",
  "Filter the animals table to only show animals that are considered threatened or endangered",
  "Add a new column to the table for the main predators of each animal and populate the cells with the appropriate data",
  "Edit the diet of the Honeybee in the table to be 'Herbivorous'",
  "Sort the animals table by size in alphabetical order",
  "Add a new animal to the table with the name 'Grizzly Bear' and its corresponding physical characteristics",
];

export const movies = [
  {
    name: "Titanic",
    release_date: 1997,
    rating: 7.8,
    genre: ["Romance", "Drama"],
  },
  {
    name: "Inception",
    release_date: 2010,
    rating: 8.8,
    genre: ["Sci-fi", "Action"],
  },
  {
    name: "The Godfather",
    release_date: 1972,
    rating: 9.2,
    genre: ["Crime", "Drama"],
  },
  {
    name: "The Shawshank Redemption",
    release_date: 1994,
    rating: 9.3,
    genre: ["Drama"],
  },
  {
    name: "Pulp Fiction",
    release_date: 1994,
    rating: 8.9,
    genre: ["Crime", "Drama"],
  },
];

export const moviePrompts = [
  "Show me all movies released in the 1990s.",
  "Sort the movies by their ratings, highest to lowest.",
  "Filter the movies by genre: only show me action movies.",
  "Add a new movie to the list: 'Interstellar', released in 2014, with a rating of 8.6 and genres 'Sci-fi' and 'Adventure'.",
  "Update the rating for 'The Shawshank Redemption' to 9.5.",
  "Remove the movie 'Titanic' from the list.",
  "Which movies have a rating of 9 or higher?",
  "Show me all movies released before 2000.",
  "Filter the movies by genre: only show me romantic comedies.",
  "Sort the movies by their release date, oldest to newest.",
  "Update the genres for 'Inception' to include 'Thriller'.",
  "Add a new movie to the list: 'The Dark Knight', released in 2008, with a rating of 9.0 and genre 'Action'.",
  "Which movies have a rating between 8.5 and 9.0?",
  "Show me all movies released in the 2000s.",
  "Filter the movies by genre: only show me horror movies.",
  "Sort the movies by their rating, lowest to highest.",
  "Update the release date for 'Pulp Fiction' to 1992.",
  "Remove the movie 'The Godfather' from the list.",
  "Which movies have a rating lower than 8.0?",
  "Show me all movies released before 1990.",
  "Filter the movies by genre: only show me animated movies.",
  "Sort the movies by their release date, newest to oldest.",
  "Update the rating for 'The Shawshank Redemption' to 9.4.",
  "Add a new movie to the list: 'The Matrix', released in 1999, with a rating of 8.7 and genre 'Sci-fi'.",
  "Which movies have a rating between 8.0 and 8.5?",
  "Show me all movies released in the 2010s.",
  "Filter the movies by genre: only show me musicals.",
  "Sort the movies by their rating, highest to lowest.",
  "Update the genres for 'Inception' to include 'Mystery'.",
  "Add a new movie to the list: 'Fight Club', released in 1999, with a rating of 8.8 and genres 'Drama' and 'Thriller'.",
];

export const programmingLanguages = [
  {
    name: "JavaScript",
    year_created: 1995,
    creator: "Brendan Eich",
    paradigms: ["Imperative", "Functional", "Object-oriented", "Event-driven"],
    file_extension: ".js",
  },
  {
    name: "Python",
    year_created: 1991,
    creator: "Guido van Rossum",
    paradigms: ["Imperative", "Functional", "Object-oriented", "Scripting"],
    file_extension: ".py",
  },
  {
    name: "C++",
    year_created: 1979,
    creator: "Bjarne Stroustrup",
    paradigms: ["Imperative", "Object-oriented", "Generic"],
    file_extension: ".cpp",
  },
  {
    name: "Java",
    year_created: 1995,
    creator: "James Gosling",
    paradigms: ["Imperative", "Object-oriented", "Class-based"],
    file_extension: ".java",
  },
  {
    name: "C#",
    year_created: 2000,
    creator: "Anders Hejlsberg",
    paradigms: ["Imperative", "Object-oriented", "Class-based"],
    file_extension: ".cs",
  },
];

export const programmingLanguagesPrompts = [
  "Add a new programming language to the table with the name 'Ruby' and its corresponding characteristics",
  "Edit the year that C++ was created in the table to be 1979",
  "Filter the programming languages table to only show languages that have a file extension ending with '.py'",
  "Add a new column to the table for common applications and populate the cells with the appropriate data",
  "Edit the creator of Java in the table to be 'James Gosling'",
  "Sort the programming languages table by year created in ascending order",
  "Add a new programming language to the table with the name 'Go' and its corresponding characteristics",
  "Edit the paradigms of Python in the table to include 'Scripting'",
  "Filter the programming languages table to only show languages that are object-oriented",
  "Add a new column to the table for current version and populate the cells with the appropriate data",
  "Edit the file extension of JavaScript in the table to be '.js'",
  "Sort the programming languages table by creator's last name in alphabetical order",
  "Add a new programming language to the table with the name 'Rust' and its corresponding characteristics",
  "Edit the paradigms of C# in the table to include 'Class-based'",
  "Filter the programming languages table to only show languages that were created in the 1990s",
  "Add a new column to the table for popularity ranking and populate the cells with the appropriate data",
  "Edit the year that Python was created in the table to be 1991",
  "Sort the programming languages table by number of paradigms in descending order",
  "Add a new programming language to the table with the name 'Swift' and its corresponding characteristics",
  "Edit the paradigms of Java in the table to include 'Class-based'",
  "Filter the programming languages table to only show languages that were created by individuals with the first name starting with 'A'",
  "Add a new column to the table for common libraries and frameworks and populate the cells with the appropriate data",
  "Edit the creator of C# in the table to be 'Anders Hejlsberg'",
  "Sort the programming languages table by file extension in alphabetical order",
  "Add a new programming language to the table with the name 'Kotlin' and its corresponding characteristics",
];
