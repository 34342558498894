import { createContext, useContext, useEffect, useState } from "react";
import {
  LOCAL_STORAGE_AMOUNT_VARIANTS,
  LOCAL_STORAGE_TEMPERATURE,
} from "./constants";

export interface GlobalStateType {
  temperature: number;
  amountCompletions: number;
}

type GlobalStateContext = [
  GlobalStateType,
  React.Dispatch<React.SetStateAction<GlobalStateType>>
];

const initialData: GlobalStateType = {
  temperature: Number(localStorage.getItem(LOCAL_STORAGE_TEMPERATURE) ?? 0.5),
  amountCompletions: Number(
    localStorage.getItem(LOCAL_STORAGE_AMOUNT_VARIANTS) ?? 3
  ),
};

export const GlobalState = createContext<GlobalStateContext>([
  { ...initialData },
  () => {},
]);

export interface GlobalStateProviderProps {
  children: React.ReactNode;
}

export const GlobalStateContextProvider = ({
  children,
}: GlobalStateProviderProps) => {
  const [globalState, setGlobalState] = useState<GlobalStateType>(initialData);

  useEffect(() => {
    localStorage.setItem(
      LOCAL_STORAGE_TEMPERATURE,
      globalState.temperature.toString()
    );
  }, [globalState.temperature]);

  useEffect(() => {
    localStorage.setItem(
      LOCAL_STORAGE_AMOUNT_VARIANTS,
      globalState.amountCompletions.toString()
    );
  }, [globalState.amountCompletions]);

  return (
    <GlobalState.Provider value={[globalState, setGlobalState]}>
      {children}
    </GlobalState.Provider>
  );
};

export const useGlobalState = () => useContext(GlobalState);

GlobalState.displayName = "GlobalState";
